import * as React from 'react';
import Button from './Button';
import Typography from '@mui/material/Typography';
import ProductHeroLayout from './ProductHeroLayout';

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundPosition: 'center',
        backgroundColor: 'gray'
      }}
    >
      <Typography color="inherit" align="center" variant="h2"  sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
        Eating out?
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { xs: 4, sm: 10 }, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}
      >
        Use this app to easily split your bill between friends!
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/login"
        sx={{ minWidth: 200 }}
      >
        Get started
      </Button>
      <Typography variant="body2" color="inherit" sx={{ mt: 2, textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'}}>
        Discover the experience
      </Typography>
    </ProductHeroLayout>
  );
}