import React from 'react';
import { Container, ThemeProvider, Box } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './api/store';
import HomePage from './pages/HomePage';
import CaptureReceiptPage from './pages/CaptureReceiptPage';
import ReceiptProcessed from './pages/ReceiptProcessed';
import AuthGuard from './guards/authGuard';
import LoginPage from './pages/Login';
import { ToastContainer } from 'react-toastify';
import themes from './styles/theme';
import bgImage from './assets/BackGround.jpeg';
import AppBarComponent from './components/AppBarComponent';
import './styles.css';
import 'react-toastify/dist/ReactToastify.css'
import LandingPage from './pages/LandingPage';
import { Privacy } from './components/Privacy';
import { Terms } from './components/Terms';

const injectGlobalStyles = (theme) => {
  const style = document.createElement('style');
  style.innerHTML = `
    html, body {
      font-family: ${theme.typography.fontFamily};
    }
  `;
  document.head.appendChild(style);
};

injectGlobalStyles(themes.dark);

function App() {
  return (
    <ThemeProvider theme={themes.dark}>
      <ToastContainer
        theme='colored'
        position="top-center"
        hideProgressBar={true}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '94vh', overflow: 'auto' }}>
          <Container
            sx={{
              flex: 1,
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0,
              backgroundImage: `url(${bgImage})`,
              backgroundPosition: '0% 66%',
              backgroundSize: 'cover',
            }}
          >
            <Provider store={store}>
              <AppBarComponent />
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/login" element={<LoginPage />} />
                </Routes>
                <AuthGuard>
                  <Routes>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/capture-receipt" element={<CaptureReceiptPage />} />
                    <Route path="/receipt-processed" element={<ReceiptProcessed />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms" element={<Terms />} />
                  </Routes>
                </AuthGuard>
              </BrowserRouter>
            </Provider>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;