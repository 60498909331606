import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import AppBar from './landing/AppBar';
import { Typography, useTheme } from '@mui/material';
import AppFooter from './landing/AppFooter';
import Markdown from './Markdown';

export function Privacy() {
    const [md, setMd] = useState<string>()
    const theme = useTheme();

    useEffect(() => {
        fetch('/documents/Privacy.md')
            .then((response) => {
                if (response.ok) return response.text();
                else return Promise.reject("Didn't fetch text correctly");
            })
            .then((text) => {
                setMd(text);
            })
            .catch((error) => console.error(error));
    });

    return (
        <React.Fragment>
            <AppBar />
            <Container>
                <Box sx={{ mt: 1, mb: 12, width: '100%' }}>
                    <Typography color={theme.palette.common.white} align="center" variant="h2"  sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                        Privacy Policy
                    </Typography>
                    <Box sx={{ width: '100%', backgroundColor: theme.palette.grey[100], borderRadius: 3, p: 5 }}>
                        <Markdown>{md}</Markdown>
                    </Box>
                </Box>
            </Container>
            <AppFooter />
        </React.Fragment>
    );
}