import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      <Link color="inherit">
        Lithium Labs
      </Link>{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: 'flex', bgcolor: 'secondary.light' }}
    >
      <Stack direction={'column'} spacing={2} sx={{ my: 4, display: 'flex', alignItems: 'center', alignSelf: 'center', width: '100%' }}>
        <Grid size={{ xs: 6, sm: 4, md: 2 }}>
          <Typography variant="h6" gutterBottom>
            Legal
          </Typography>
          <Box component="ul" sx={{ m: 0, listStyle: 'none', p: 0 }}>
            <Box component="li" sx={{ py: 0.5 }}>
              <Link href="/terms">Terms</Link>
            </Box>
            <Box component="li" sx={{ py: 0.5 }}>
              <Link href="/privacy">Privacy</Link>
            </Box>
          </Box>
        </Grid>
        <Grid size={{ xs: 6, sm: 4, md: 3 }}>
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ justifyContent: 'flex-center' }}
          >
            <Grid>
              <Copyright />
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Typography>
  );
}