import * as React from 'react';
import AppBar from '../components/landing/AppBar';
import ProductHero from '../components/landing/ProductHero';
import ProductHowItWorks from '../components/landing/ProductHowItWorks';
import AppFooter from '../components/landing/AppFooter';
import FeedbackButton from '../components/FeedbackButton';

function LandingPage() {
    return (
        <>
            <AppBar />
            <ProductHero />
            <ProductHowItWorks />
            <FeedbackButton needHelp={true} />
            <AppFooter />
        </>
    );
}

export default LandingPage;
