import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AuthGuard({children}) {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const poolId = searchParams.get("poolId");
    const user = useSelector((state: any) => state.user);

    useEffect(() => {
        if (!user.userId) {
            if (poolId) {
                navigate(`/login?poolId=${poolId}`);
            } else {
                navigate('/login')
            }
        } else if (poolId) {
            navigate(`/receipt-processed?poolId=${poolId}`);
        }
    }, [navigate, poolId, user.userId])

    return <>{children}</>
}