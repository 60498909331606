import * as React from 'react';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import DoneIcon from '@mui/icons-material/Done';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ShareIcon from '@mui/icons-material/Share';
import { useState } from 'react';

export default function ReceiptProcessedSpeedDial({
    onShare,
    onTip,
    onReset,
    onAdd,
    onHelp,
    onFinish
}: {
    onShare: () => void,
    onTip: () => void,
    onReset: () => void,
    onAdd: () => void,
    onHelp: () => void,
    onFinish: () => void
}) {
    const actions = [
        { icon: <ShareIcon />, name: 'Share', onClick: onShare },
        { icon: <AttachMoneyIcon />, name: 'Tip', onClick: onTip },
        { icon: <RestartAltIcon />, name: 'Reset', onClick: onReset },
        { icon: <AddIcon />, name: 'Add', onClick: onAdd },
        { icon: <HelpIcon />, name: 'Help', onClick: onHelp },
        { icon: <DoneIcon />, name: 'Finish', onClick: onFinish },
    ];

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (

        <SpeedDial
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            ariaLabel="Actions menu"
            sx={{ position: 'absolute', top: 62, right: 5 }}
            icon={<SpeedDialIcon />}
            direction='down'
            FabProps={{
                size: 'small',
                color: 'secondary'
            }}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    slotProps={{
                        tooltip: {
                            title: action.name
                        }
                    }}
                    onClick={() => {
                        handleClose();
                        action.onClick();
                    }}
                />
            ))}
        </SpeedDial>

    );
}
