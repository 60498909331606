import * as React from 'react';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Container from '@mui/material/Container';
import Button from './Button';
import Typography from '@mui/material/Typography';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import ShareIcon from '@mui/icons-material/Share';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import LooksThreeIcon from '@mui/icons-material/Looks3';

const item: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const image = {
  my: 2,
  fontSize: 42
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden', borderRadius: 5 }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
          How it works
        </Typography>
        <div>
          <Grid container spacing={4}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box sx={item}>
                {/* <Box sx={number}>1</Box> */}
                <LooksOneIcon sx={{fontSize: 32}} />
                <Box>
                  <CameraIcon sx={image}></CameraIcon>
                </Box>
                <Typography variant="h5" align="center">
                  Snap a picture of your bill.
                </Typography>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box sx={item}>
              <LooksTwoIcon sx={{fontSize: 32}} />
                <ShareIcon sx={image}></ShareIcon>
                <Typography variant="h5" align="center">
                  Invite your dining party to join in. Everyone picks the items they ordered.
                </Typography>
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Box sx={item}>
                <LooksThreeIcon sx={{fontSize: 32}} />
                <AttachMoneyIcon sx={image} ></AttachMoneyIcon>
                <Typography variant="h5" align="center">
                  Easily add a tip and see your total due.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/login"
          sx={{ mt: 8 }}
        >
          Get started
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;